html {
  background-color: #fff;
  color: rgb(68, 68, 68);
  overflow-x: hidden;
  line-height: 1.25;
}

code {
  white-space: pre-wrap; /* 자동 줄바꿈 활성화 */
  word-wrap: break-word; 
}

a {
  color: black;
  text-decoration: none;
  background-color: rgb(243, 243, 243);
  box-shadow: 3px 0px 0px 0 rgb(116, 197, 163);
  padding: 0 0.5em 0 0.5em;
  transition: 0.3s;

  &:hover {
  background-color: rgb(230,250,250);
  box-shadow: 1px 0px 0px 0 rgb(116, 197, 163);
  }
}

hr{
  border-top: 1px solid rgb(0,0,0);
  border-bottom: none;
  border-left: none;
  border-right: none;
}

.footer{
  padding: 10em 0 1em 0;
  margin: auto;
  text-align: center;
  color:rgb(150,150,150);
  font-size: 0.9em;
}

li{
  margin: 2px;
}
/*//////NAVBAR///////*/
.navBar {
  top:0px;
  padding: 1em 2em 0.5em 2em;
  background-color: rgb(255, 255, 255);
  overflow: hidden;
  transition: 0.5s;
  
  #navContents{
    display: flex;
    a {
      box-shadow: none;
      transition:0.3s;
      background-color: white;
      &:hover {
        background-color: white;
        color: rgb(116, 197, 163);}
      }

    #name{
      font-size: 3em;
      color: black;
      text-align: left;
      transition:0.3s;
      margin: 0;
      padding: 0;
      align-self: flex-end;
      &:hover {
        background-color: white;
        color: rgb(116, 197, 163);
      }
      h1{
        padding:0;
        margin: 0;
      }
    }

    #nav{
      margin-left:auto;
      align-self: flex-end;
      display: flex;
      text-align: right;
      top: 0;
      font-size: 1.5em;
      padding-bottom: 1.5em;
      color: black; 
      #navbuttons{
        a{
          padding-top: 0.5em;
          display: block;
        }
      }       
      p{
        padding-top: 0.15em;
        transform: scaleY(4);
      }
      }
    }
}

@media (max-width: 650px) {
  .navBar {
    top:0px;
    padding: 1em 0.5em 0.5em 0.5em;
    background-color: rgb(255, 255, 255);
    overflow: hidden;
    transition: 0.5s;
    
    #navContents{
      display: flex;
      a {
        box-shadow: none;
        transition:0.3s;
        background-color: white;
        &:hover {
          background-color: white;
          color: rgb(116, 197, 163);}
        }
  
      #name{
        font-size: 1em;
        color: black;
        text-align: left;
        transition:0.3s;
        margin: 0;
        padding: 0;
        align-self: flex-end;
        &:hover {
          background-color: white;
          color: rgb(116, 197, 163);
        }
        h1{
          padding:0;
          margin: 0;
        }
      }
  
      #nav{
        margin-left:auto;
        align-self: flex-end;
        display: flex;
        text-align: right;
        top: 0;
        font-size: 1.5em;
        color: black; 
        padding-bottom: 0.25em;
        #navbuttons{
          align-self: flex-end;
          a{
            padding: 0.1em 0 0 0;
            margin: 0;
            display: block;
          }
        }       
        p{
          visibility: hidden;
        }
        }
      }
  }
}

/*//////MAIN///////*/
.main{
  .keyword{
    padding: 0.5em 1em 0.5em 1em; 
    margin: 0;
    text-align: center;
    background-color: black;
    color: white;
    letter-spacing: 10px;
  }
  // .firstcut{
  //   display: grid;
  //   grid-template-columns: 16.6vw 16.6vw 16.6vw 16.6vw 16.6vw 16.6vw;
  //   grid-template-rows: 16.6vw 16.6vw 16.6vw 16.6vw 16.6vw 16.6vw 16.6vw 16.6vw 16.6vw 16.6vw;
  //   .music{
  //     padding: -0.5em;    
  //     background-color: rgb(0, 0, 0);
  //     grid-column: 1 / 5; 
  //     grid-row: 1/2;
  //     h1{
  //       margin-top: 0.1em;
  //       margin-left: -0.1em;
  //       font-size: 10.5em;
  //       color: white;
  //     }
  //   }
  //   .explanation{
  //     padding: 0 5em 0 0;
  //     font-size: 2em;
  //     background-color:  white;
  //     //text-align: right;
  //     color: rgb(0, 0, 0);
  //     grid-column: 1 / 5; 
  //     grid-row: 2/3;
  //     h2{
  //       margin: auto;
  //     }
  //   }
  //   .recent{
  //     color: black;
  //     grid-column: 3 / 7; 
  //     grid-row: 1 / 5;
  //     text-align: right;
  //     padding: 2em;
  //     background-image: url("https://firebasestorage.googleapis.com/v0/b/portfolio-f9959.appspot.com/o/Fantastic%20AI%20Sinawi%2Fthumbnail.jpg?alt=media&token=32206640-57e0-4f2e-81c2-c5c700092163");
  //     background-size: cover;
  //     clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
  //   }
  //   .idea{
  //     grid-column: 1 / 5; 
  //     grid-row: 3 / 7;
  //     background-color: black;
  //   }
  //   .canvas{}
  // }
    a {
      background-color: transparent;
      box-shadow: none;
    }
    .recent{
      color: white;
      text-align: right;
      padding: 2em;
      background-image: url("https://firebasestorage.googleapis.com/v0/b/portfolio-f9959.appspot.com/o/doongdoong.club%2Fthumbnail.jpg?alt=media&token=d0059008-cea3-4d90-91c2-04b268bf1632");
      background-size: cover;
      transition: 0.5s;
      h2{
        padding-bottom: 0em;
        margin-bottom: 0em;
      }
      //padding-bottom: 56.25%;
      padding-bottom: 40%;
      &:hover {
        color: white;
        opacity: 80%;
      }
    }
  
}


/*//////WORK///////*/
.work {
  margin: auto;
  padding: 0 0 2em 0;
  
  .header {
    background-color:black;
    margin:0; 
    z-index: 999;
    #title{
      display: flex;
      h1 {
        padding: 0;
        color: white;
        margin:0.5em 0 0 1em;
      }
      .year{
        color: white;
        margin:1em 0 0.3em 1em;
        align-self: flex-end;
      }
    }
    #tag{
      padding: 0 0 1em 1.7em;
      background-color:black;
      button {
        margin: 2px 3px 2px 3px;
        margin-left: 0;
        background-color: black;
        border-radius: 3px;
        border: 1px solid white;
        color: white;
      }
    }
  }
  @media (max-width: 500px) {
    .header {
      background-color:black;
      z-index: 999;
      margin:0; 
      #title{
        display: flex;
        flex-wrap: wrap;
        padding-left: 2.5em;
        h1 {
          padding: 0;
          color: white;
          margin:0.2em 0 0 0em;
        }
        .year{
          color: white;
          margin:0 0 0.3em 0em;
          align-self: flex-end;
        }
      }
      #tag{
        padding: 0 0 0.4em 2.5em;
        background-color:black;
        button {
          margin: 0 3px 2px 3px;
          margin-left: 0;
          background-color: black;
          border-radius: 3px;
          border: 1px solid white;
          color: white;
        }
      }
    }
  }
  .contents{
    padding: 0 2em 0 2em;
    margin: auto;
    max-width: 1000px;
      img {
    max-width: 100%;
  }
  }
  
  .video-container {
    position: relative;
    margin: 1em 0 1em 0;
    padding-bottom: 56.25%;
    border:none;
    object {
      border:none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      
    }
  }
}

/*//////ABOUT//////*/

.about{
  .contents{
    padding: 0 2em 2em 2em;
    max-width: 800px;
    margin-left: 0;
    .bio{
      margin: 4em 0 2em 0;
    }
  }

  .intro{
    display:block;
    margin: 0;
    img{
      margin:0;
      width: 100%;
    }
    .text{
      background: black;
      color: white;
      padding: 2em;
    }
  }
  
  @media (min-width: 650px) {
    .contents{
      margin: 0;
      padding: 0 2em 2em 2em; 
      .bio{
        margin: 4em 0 2em 0;
      }
    }
    .intro{
      width: 100vw;
      display: flex;
      background-color: black;

      img{
        width: 50vw;
        max-width: 400px;
      }
      .text{
        background: black;
        color: white;
        padding: 2em;
      }
    } 
  }
}


/*//////CV//////*/
.CV{
  margin: auto;
  .education{
    .edu{
      margin: 1em 0 1em 0;
      .item{
        display:flex;
        flex-wrap: nowrap;
        flex-direction: row;
        margin: 0.25em 0 0.25em 0;
        .year{
          width: 2em;
          padding-right: 1em;
        }
        .content{
          padding-right: 1em;
        }
      }
    }
  }

  .awards{
      .item{
        display:flex;
        flex-wrap: nowrap;
        flex-direction: row;
        margin: 0.5em 0 0.5em 0;
        .year{
          width: 2em;
          padding-right: 1em;
        }
        .content{
          padding-right: 1em;
        }
      }
  }

  .skills{
    .item{
      display:block;
      margin: 0.5em 0 0.5em 0;
      .name{
        font-weight: 400;
        margin: 0.5em 0 0.2em 0;
        padding: 0.2em 0 0.2em 0;
    }}

  }

  .exhibitions{
    .item{
      margin: 1em 0 1em 0;
    }
    
    .line{
      display:flex;
      flex-wrap:nowrap;
    
      .year{
        padding-right: 1em;
        width: 2em;
      }
      
      .content{
        display:flex;
        flex-wrap:nowrap;
        flex-direction: column;
        
        .place{
          display:flex;
          flex-wrap:nowrap;
          flex-direction: column;
          margin: 0.2em 0 0.2em 0;
          font-size: 0.9em;
          
        }
        .title{
          display:flex;
          flex-wrap:wrap;
          flex-direction: row;
          padding-right: 0.5em;
          .tag{
            padding-right: 0.05em;
            margin: 0 0 0.2em 0;
          } 
          .content{
            font-weight: 400;
          }
        }
      }
    }
  }


}

/*//////WORKS//////*/
.worklist {
  margin: auto;
  display:grid;
  grid-template-columns: auto auto auto;
  gap: 5px 5px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  .works{
    position: relative;
    margin:10px;   
    display: flex;
    justify-content:center; 
    a{
      min-width: 185px;
      img {
        padding: 3px;
        width: 20vw;
        height: 14.6vw;
        max-width: 440px;
        max-height: 320px;
        object-fit: cover;
      }
      .name {
        padding: 0em 0.2em 0.2em 0.2em;
        text-align: right;
        vertical-align: bottom;
      }
    }
    .tag{
      opacity: 0;
      position: absolute;
      display: flex;
      flex-direction: column;
      top: 3px;
      right: auto;
      margin-left: 3px;
      button {
        margin: 2px 3px 0px 2px;
        border-radius: 3px;
        border: 1px solid white;
        background-color: rgba(0,0,0,0.7);
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        padding-top: 1px;
        color: rgba(255,255,255,0.7);
        cursor: pointer;
        font-size: 0.7rem;
      }
    }
  }
}

@media (max-width: 700px) {
  .worklist {
    margin:auto;
    grid-template-columns: auto auto;
    .works{ 
      .tag{
        opacity: 0;
      }
    }
  }
}

@media (max-width: 500px) {
  .worklist {
    display:flex;
    flex-wrap: wrap;
    margin:0;
    padding: 2em 0 0 0;
    .works{
      margin: 1px;
      padding-bottom: 2em;
      display: flex;
      flex-wrap: row wrap;
      justify-content:center;
      a{
        width: 100%;
        background-color: #fff;
        box-shadow: none;
        img {
          float: left;
          padding: 3px;
          width: 80vw;
          height: 58.4vw;
          max-width: 440px;
          max-height: 320px;
          object-fit: cover;
        }
        .name {
          padding: 0em 1em 0.2em 0.2em;
          text-align: right;
          vertical-align: bottom;
        }
      }
      .tag{
        opacity: 1;
        right: auto;
        button{
          font-size: 0.5rem;
        }      
      }
    }
  }
}

@media (min-width: 1000px) {
  .worklist {
    margin:auto;
    grid-template-columns: auto auto auto auto;
    gap: 1em 1em;
    a{
      min-width: 185px;
      img {
        padding: 3px;
        width: 20vw;
        height: 14.6vw;
        max-width: 440px;
        max-height: 320px;
        object-fit: cover;
      }
      .name {
        padding: 0em 0.2em 0.2em 0.2em;
        text-align: right;
        vertical-align: bottom;
      }
    }
    .works{
      .tag{
        opacity: 1;
      }
    }

  }
}