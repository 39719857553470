@import url("https://use.typekit.net/azc2cqn.css");
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.5/dist/web/static/pretendard.css");

body {
  margin: 0;
  
  font-family: Pretendard, sans-serif;
  font-weight: 200;
  font-style: normal;


  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.footer{
  font-family: forma-djr-text, sans-serif;
  font-weight: 300;
  font-style: italic;
}

.body h1{
  font-family: forma-djr-banner, sans-serif;
  font-weight: 700;
  font-style: italic;  
}


.body .year{
  font-family: forma-djr-text, sans-serif;
  font-weight: 300;
  font-style: normal;
}


 h2, .worklist{  
  font-family: forma-djr-text, sans-serif;
  font-weight: 300;
  font-style: normal;
}
@media (max-width: 500px) {
  h2, .worklist{  
    font-family: forma-djr-text, sans-serif;
    font-weight: 500;
    font-style: normal;
  }
}

.navBar, .Main, h1{
  font-family: forma-djr-text, sans-serif;
  font-weight: 700;
  font-style: italic;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button{
  font-family: forma-djr-text, sans-serif;
  font-weight: 300;
  font-style: normal;
}
